import React from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import './content-block.scss';

export default ({
  ctaTitle = 'More info',
  ctaUrl,
  date,
  city,
  headline,
  description,
}) => {
  return (
    <li className="content-block">
      <div className="content-block-header">
        <span className="event-date">{date}</span>,{' '}
        <span className="event-city">{city}</span>
      </div>
      <h3>{headline}</h3>
      <p>{description}</p>
      {ctaUrl && (
        <a href={ctaUrl} target="_blank" rel="noopener noreferrer">
          <span>{ctaTitle}</span>
          <ArrowForwardIosIcon color="primary" />
        </a>
      )}
    </li>
  );
};
