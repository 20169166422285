import React from 'react';
import { Instagram, Facebook, Twitter, YouTube } from '@material-ui/icons';
import c from 'classnames';
import './hero.scss';
import { ArrowForward } from '@material-ui/icons';

export default ({ className, showSocial = true, title, imgSrc, imgAlt }) => {
  return (
    <>
      <div className={c('hero', { [className]: className })}>
        <span className="hero-title">
          {!title ? (
            <>
              <h2>Kevin Muico</h2>, <br />
              <h1>Catholic speaker</h1>
            </>
          ) : (
            <h1>{title}</h1>
          )}
        </span>
        <p className="hero-tagline">
          He has spoken to thousands of youth in Canada, USA, Bermuda, Kenya,
          Philippines, and Australia about the good of the good news through the
          lens of Theology of the Body.
        </p>
        <a
          className="hero-action"
          href="mailto:kmuico@gmail.com?subject=Speaking Engagement Inquiry"
        >
          Invite Kevin to speak
          <ArrowForward className="icon-arrow" fontSize="large" />
        </a>
        {imgSrc && <img src={imgSrc} alt={imgAlt} className="hero-image" />}
      </div>
      {showSocial && (
        <div className="social">
          <span className="social-label">STAY CONNECTED</span>
          <ul className="social-list">
            <li>
              <a
                href="https://www.facebook.com/KevinMuico"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook fontSize="large" color="primary" />
              </a>
            </li>
            <li>
              <a
                href="https://www.twitter.com/kevinmuico"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter fontSize="large" color="primary" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/kevinmuico"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram fontSize="large" color="primary" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/kevinmuico"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YouTube fontSize="large" color="primary" />
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};
