import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import './index.scss';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

import Layout from 'components/Layout';
import Hero from 'components/Hero';
import ContentBlock from 'components/ContentBlock';

class RootIndex extends React.Component {
  state = {
    name: '',
    email: '',
    botInput: '',
  };

  handleNameChange = ({ target }) => {
    this.setState({
      name: target.value,
    });
  };

  handleEmailChange = ({ target }) => {
    this.setState({
      email: target.value,
    });
  };

  handleBotInput = ({ target }) => {
    this.setState({
      botInput: target.value,
    });
  };

  render() {
    const { data } = this.props;

    const {
      site: { siteMetadata },
    } = data;

    console.log(data.site);

    return (
      <>
        <Helmet>
          <title>{siteMetadata.title}</title>
          <meta name="description" content={siteMetadata.description} />
          <meta
            name="keywords"
            content='Catholic Speaker, "Catholic Speaker", Catholic Speakers, "Catholic Speakers", top catholic speakers, "top catholic speakers", parish mission, "Parish Mission", "Catholic conference speaker", Catholic conference speaker, "Catholic Minute", Catholic Minute, Catholic speaker for youth, "Catholic speaker for youth"'
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={siteMetadata.twitterUsername} />
          <meta name="twitter:title" content={siteMetadata.title} />
          <meta name="twitter:description" content={siteMetadata.description} />
          <meta
            name="twitter:image"
            content="/images/catholic-speaker-addressing-youth-social-media.jpg"
          />
          <meta
            name="twitter:image:alt"
            content="Kevin catechizing an audience of young Catholics"
          />
          <meta property="og:description" content={siteMetadata.description} />
          <meta property="og:title" content={siteMetadata.title} />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="/images/catholic-speaker-addressing-youth-social-media.jpg"
          />
          <meta name="robots" content="index, follow" />
        </Helmet>
        <Layout active="home">
          <section id="hero">
            <Hero
              imgSrc="/images/kevin.png"
              imgAlt="Kevin Muico Catholic Speaker"
            />
            {/* NEWSLETTER */}
            <div className="newsletter">
              <span className="newsletter-label">Stay up to date</span>
              <div id="mc_embed_signup" className="newsletter-form">
                <form
                  action="https://facebook.us15.list-manage.com/subscribe/post?u=47f8033ed7a5873ef313a18cc&amp;id=63afa961e2"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className="validate"
                  target="_blank"
                  rel="noopener noreferrer"
                  noValidate
                >
                  <div
                    id="mc_embed_signup_scroll"
                    className="newsletter-input-wrapper"
                  >
                    <div className="mc-field-group">
                      <label htmlFor="mce-FNAME">NAME</label>
                      <input
                        type="text"
                        value={this.state.name}
                        name="FNAME"
                        style={{}}
                        id="mce-FNAME"
                        onChange={this.handleNameChange}
                      />
                    </div>
                    <div className="mc-field-group">
                      <label htmlFor="mce-EMAIL">EMAIL ADDRESS</label>
                      <input
                        type="email"
                        value={this.state.email}
                        name="EMAIL"
                        className="required email"
                        id="mce-EMAIL"
                        onChange={this.handleEmailChange}
                      />
                    </div>
                    <div id="mce-responses" className="clear">
                      <div
                        className="response"
                        id="mce-error-response"
                        style={{ display: 'none' }}
                      />
                      <div
                        className="response"
                        id="mce-success-response"
                        style={{ display: 'none' }}
                      />
                    </div>
                    {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                    <div
                      style={{ position: 'absolute', left: '-5000px' }}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_47f8033ed7a5873ef313a18cc_63afa961e2"
                        tabIndex="-1"
                        value={this.state.botInput}
                        onChange={this.handleBotInput}
                      />
                    </div>
                    <div className="clear mc-field-group">
                      <input
                        type="submit"
                        value="Submit"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="button"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
          <section id="about">
            <div className="about">
              <div className="about-title">
                <h2>About</h2>
                <figure className="about-image-wrapper">
                  <img
                    src="/images/about.jpg"
                    className="about-image"
                    alt="Kevin speaking at a conferenece"
                  />
                </figure>
              </div>
              <div className="about-content">
                <p>
                  Kevin Muico is a lay missionary of six years serving the youth
                  and young adult ministries of Couples for Christ Canada. He
                  has spoken to thousands of youth in Canada, USA, Bermuda,
                  Kenya, Philippines, and Australia about the good of the good
                  news through the lens of Theology of the Body.
                </p>
                <p>
                  Passionate in sharing the faith creatively, Kevin also
                  co-hosts and produces the podcast “
                  <a
                    href="https://anchor.fm/theignitecast"
                    className="link-out"
                    alt="podcast for the ignitecast"
                  >
                    The Ignitecast
                  </a>
                  ” with other missionaries where they share stories of faith,
                  community and pop-culture. Kevin is based in Toronto with his
                  wife Tisa and is currently the National Evangelization and
                  Missions Coordinator of Couples For Christ Canada.
                </p>
                <a
                  href="mailto:kmuico@gmail.com?subject=Speaking Engagement Inquiry"
                  className="about-button"
                >
                  SPEAK AT YOUR EVENT
                </a>
              </div>
            </div>
          </section>
          <section id="testimonials">
            <div className="testimonials">
              <h2>Testimonials</h2>
              <div className="testimonials-content">
                <div className="testimonials-item">
                  <FormatQuoteIcon className="icon-quote" fontSize="large" />
                  <span className="testimonials-quote">
                    His enthusiasm and knowledge when speaking about our
                    Catholic Faith keeps everyone engaged and entertained! He
                    also has the ability to connect to youth and adults alike,
                    which is truly a gift!
                  </span>
                  <div className="testimonials-from">
                    <figure>
                      <img src="/images/john-acosta.jpg" alt="John Acosta" />
                    </figure>
                    <div>
                      <span className="testimonials-person-name">
                        John Acosta
                      </span>
                      <span className="testimonials-person-title">
                        Director of Youth and Young Adult Ministry (Archdiocese
                        of Winnipeg)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="events">
            <div className="events">
              <figure className="events-image-wrapper">
                <img
                  className="events-image"
                  src="/images/events.jpg"
                  alt="Kevin speaking to hundreds of young adults"
                />
              </figure>
              <div className="events-title-wrapper">
                <h2>Upcoming Events</h2>
              </div>
              <ul className="events-content-wrapper">
                <ContentBlock
                  date="MAY 3, 2020"
                  headline="Youth Night with Kevin Muico"
                  description="St Vincent Ferrer Youth Ministry"
                />
                <ContentBlock
                  ctaUrl="https://kevinmuico.krtra.com/t/5mzxEUVTiMoQ?tracking_id1=XXX&tracking_id2=XXX&cpc=X.XX"
                  date="MAY 8-10, 2020"
                  city="ONLINE"
                  headline="Theology of the Body Virtual Conference"
                  description={`Hosted by Theology of the Body Institute, features dozens of prominent speakers and artists.`}
                />
                <ContentBlock
                  ctaUrl="https://kevinmuico.krtra.com/t/u1sMzZni8foQ?tracking_id1=XXX&tracking_id2=XXX&cpc=X.XX"
                  date="MAY 22-24, 2020"
                  headline="Catholic Love Life Conference"
                  description="Chastity Project"
                />
                {/* <ContentBlock
                  date="APR 15, 2020"
                  city="TORONTO"
                  headline="Mission and Vocation"
                  description="Duc in Altum"
                /> */}
              </ul>
            </div>
          </section>
        </Layout>
      </>
    );
  }
}

export default RootIndex;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        url
        image
        twitterUsername
      }
    }
  }
`;
